import { Model } from '@vuex-orm/core'
// import axiosHelper from '@/api/AxiosInstance'
import Author from './Author'
import Organization from './Organization'

export default class NarrativePhoneAccount extends Model {
  static entity = 'narrativePhoneAccounts'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      narrative_phone_id: this.attr(''),
      user_id: this.attr(null),
      organization_id: this.attr(null),
      service_using_organization_member_id: this.attr(null),
      display_to_family: this.boolean(true),
      display_to_organization: this.boolean(true),
      display_to_member: this.boolean(true),
      display_to_user: this.boolean(false)
    }
  }

  get author () {
    if (this.user_id) {
      return Author.find('usr' + this.user_id)
    } else if (this.service_using_organization_member_id) {
      return Author.find('mem' + this.service_using_organization_member_id)
    }
    return null
  }

  get organization () {
    if (this.organization_id) {
      return Organization.find(this.organization_id)
    }
    return null
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData(data) {
    var npAccount = [null]
    if (data.id) {
      var entities = await NarrativePhoneAccount.insertOrUpdate({ data: data })
      npAccount = entities.narrativePhoneAccounts
    }
    NarrativePhoneAccount.commit((state) => {
      console.log(state)
      state.fetchedAt = new Date()
    })
    console.log(npAccount)
    return npAccount[0]
  }

  static apiConfig = {
    actions: {
      fetch () {
        return this.request({
          method: 'get',
          url: 'narrative_phone',
          withCredentials: true,
          save: false
        })
        .then(async res => {
          console.log(res)
          return NarrativePhoneAccount.updateData(res.response.data)
        })
      },

      fetchByAuthor ({author}) {
        console.log('fetchByAuthor')
        console.log(author)
        return this.request({
          method: 'get',
          url: 'narrative_phone/show_by_author',
          withCredentials: true,
          params: { author_id: author.id },
          save: false
        })
        .then(async res => {
          // authorの場合、複数のNarrativePhoneAccountを持てないので、ロード済みのものを全削除する
          if (author.isUser) {
            let exNP = await NarrativePhoneAccount.query().where('user_id', author.rawID).get()
            for(var en of exNP) {
              await en.$delete()
            }
          }
          console.log(res)
          return NarrativePhoneAccount.updateData(res.response.data.narrative_phone_account)
        })
      },

      fetchOrganizationAccounts ({organizationID}) {
        return this.request({
          method: 'get',
          url: 'narrative_phone/show_organization_accounts',
          withCredentials: true,
          params: { organization_id: organizationID },
          save: false
        })
        .then(async res => {
          console.log(res)
          return res.response.data.narrative_phone_accounts
        })
      }
    }
  }
}