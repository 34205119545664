import {
  TimelineOwner,
  Author,
  SigninUser,
  NarrativePhoneAccount
} from '@/models'

import {
  ArchiveData,
  AppSigninToken,
  AuthenticationActivateInvitation,
  OmronConnectAccount,
  Permission,
  StaticIdentifyCode,
  // StaticIdentifiyCodeCategory,
  User,
  UserBasic,
  UserAddress,
  ZoomUser
} from '@/api'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
// import * as ZOOM_CONF from '@/assets/constants/zoomConf.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  props: {
    loggedInUser: Object,
    signinMember: Object,
    zoomUser: Object
  },

  data () {
    return {
      timelineOwner: null,
      userBasicSource: null,
      userAddressSource: null,
      showPasswordUpdateModal: false,
      showUserBasicEditModal: false,
      showUserAddressEditModal: false,
      showUserMailEditModal: false,
      showRevokeProcessModal: false,
      checkRevokeProcess: false,
      archiveRequests: [],
      archiveLimited: true,
      deputizing: false,
      omronConnectAccountAvailable: false,
      authenticationAcitvateInvitation: null,
      showAcitivateInvitationModal: false,
      appSigninTokens: [],
      registedQrCodes: [],
      narrativePhoneAccount: null
    }
  },

  computed: {
    ownProfile () {
      if (this.loggedInUser && this.timelineOwner && this.loggedInUser.id === this.timelineOwner.id) {
        return true
      } else {
        return false
      }
    },

    subHeaderVisible () {
      if (this.ownProfile || this.deputizing) {
        return false
      } else {
        return true
      }
    }
  },

  watch: {
    '$route': 'initTimelineOwner',
    loggedInUser: { handler: 'initTimelineOwner', immediate: true },
    timelineOwner: { handler: 'loadProfiles', immediate: true }
  },

  mounted () {
    window.addEventListener('focus', this.handleWindowFocus)
  },

  beforeDestroy () {
    window.removeEventListener('focus', this.handleWindowFocus)
  },

  methods: {
    handleWindowFocus () {
      // console.log('now, focus-update is ignore for debuging...')
      this.initTimelineOwner()
    },

    async initTimelineOwner () {
      if (this.$route.params.id) {
        if (this.$route.params.id[0] === 'omron_connect') {
          this.timelineOwner = this.loggedInUser
          if (this.$route.query.kii_succeeded) {
            // to store omron connect user id
            // console.log('on profile, id equals omron_connect')
            // console.log(this.$route.query)
            // console.log(this.$route.query.provider_user_id)
            OmronConnectAccount.create({ targetUserID: this.timelineOwner.id, omronConnectID: this.$route.query.provider_user_id }).then(res => {
              // store omron_connect_account available
              console.log(res)
              this.omronConnectAccountAvailable = true
              this.showConfirmDialog(
                'OMRON CONNECTアカウントの連携完了',
                'OMRON CONNECTアカウントの連携を完了しました。\n今後、連携している機器からのデータが自動的に取得されます。',
                DIALOG_TYPE.CONFIRM,
                () => {}
              )
              return true
            }).catch(error => {
              console.error(error.response)
              // show account store error
              this.showConfirmDialog(
                'OMRON CONNECTアカウントの連携失敗',
                'OMRON CONNECTアカウントの連携が失敗しました。',
                DIALOG_TYPE.CONFIRM,
                () => {}
              )
            })
          } else {
            // show failed
            console.log(this.$route.params)
            this.showConfirmDialog(
              'OMRON CONNECTアカウントの連携失敗',
              'OMRON CONNECTアカウントの連携が失敗しました。',
              DIALOG_TYPE.CONFIRM,
              () => {}
            )
          }
        } else {
          await TimelineOwner.api().fetch({ timelineOwnerID: this.$route.params.id[0] }).then(async res => {
            console.log('on profile, paramator ID given, ' + this.$route.params.id[0])
            console.log(res)
            if (res) {
              var owner = TimelineOwner.find(this.$route.params.id[0])
              if (!Author.find(owner.author_id)) {
                await Author.api().fetchByID({ timelineOwnerID: owner.id, idList: [owner.author_id] })
              }
              this.timelineOwner = owner
              if (this.$route.query.deputize) {
                await Permission.configure({ targetUserID: this.timelineOwner.id }).then(() => {
                  this.deputizing = true
                }).catch(() => {
                  this.deputizing = false
                })
              }
            } else {
              this.$router.push({ path: '/permission_denied' })
            }
          }).catch(error => {
            console.error(error)
            this.$router.push({ path: '/permission_denied' })
          })
        }
      } else if (this.loggedInUser) {
        this.timelineOwner = this.loggedInUser
      } else if (this.signinMember) {
        if (!this.timelineOwner) {
          await TimelineOwner.api().fetch({ timelineOwnerID: this.signinMember.user_id })
          await Author.api().fetchByID({ idList: [this.signinMember.author_id] })
          this.timelineOwner = TimelineOwner.find(this.signinMember.user_id)
        }
      }
      if (this.timelineOwner && !this.timelineOwner.selfSignin) {
        this.loadAuthenticationActivateInvitation()
        this.loadNarrativePhoneAccount()
      }
      // fetching OMRON CONNECT ACCOUNT
      if (this.timelineOwner) {
        console.log('VUE_APP_OMRON_CONNECT_AVAILABLE : ' + process.env.VUE_APP_OMRON_CONNECT_AVAILABLE)
        if (process.env.VUE_APP_OMRON_CONNECT_AVAILABLE) {
          await OmronConnectAccount.fetch({ targetUserID: this.timelineOwner.id }).then(res => {
            console.log('check omron connect accout.............')
            console.log(res)
            if (res.omronConnectAccount) {
              this.omronConnectAccountAvailable = true
            }
          })
        }
        if (process.env.VUE_APP_MEETING_AVAILABLE) {
          if (this.$route.query.redirect_message) {
            if (this.$route.query.redirect_message[0] === 'zoom_account_registed') {
              this.$emit('reloadZoomUserEvent')
              this.showConfirmDialog(
                'ZOOMアカウントの連携完了',
                'ZOOMアカウントの連携を完了しました。\n今後、テレビ会議の利用が可能となります。',
                DIALOG_TYPE.CONFIRM,
                () => {}
              )
            }
            if (this.$route.query.redirect_message[0] === 'zoom_account_regist_fail') {
              this.showConfirmDialog(
                'ZOOMアカウントの連携失敗',
                'ZOOMアカウントの連携に失敗しました。',
                DIALOG_TYPE.CONFIRM,
                () => {}
              )
            }
          }
        }
      } else {
        // this.$router.push({ name: 'Forbidden' })
      }
      return true
    },

    loadAuthenticationActivateInvitation () {
      AuthenticationActivateInvitation.fetch({ targetUserID: this.timelineOwner.id }).then(res => {
        this.authenticationAcitvateInvitation = res.authentication_activate_invitation
        return this.authenticationAcitvateInvitation
      })
    },

    loadProfiles () {
      if (this.timelineOwner) {
        this.loadUserBasic()
        this.loadUserAddress()
        if (
          (this.loggedInUser && this.loggedInUser === this.timelineOwner) ||
          (this.signinMember && this.signinMember.user_id === this.timelineOwner.id) ||
          this.deputizing
        ) {
          this.loadArcives()
          this.loadAppSigninTokens()
          this.loadRegistedQrcodes()
          this.loadOmronConnectAccount()
          this.loadNarrativePhoneAccount()
        }
      }
    },

    loadUserBasic () {
      if (this.timelineOwner) {
        UserBasic.fetch({ timelineOwnerID: this.timelineOwner.id }).then(res => {
        // this.$store.dispatch('fetchUserBasic', { timelineOwnerID: this.timelineOwner.id }).then(res => {
          this.userBasicSource = res.user_basic
          return this.userBasicSource
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('データ取得失敗', '基本情報データの取得に失敗しました')
        })
      }
    },

    loadUserAddress () {
      if (this.timelineOwner) {
        UserAddress.fetch({ timelineOwnerID: this.timelineOwner.id }).then(res => {
        // this.$store.dispatch('fetchUserAddress', { timelineOwnerID: this.timelineOwner.id }).then(res => {
          this.userAddressSource = res.user_address
          return this.userAddressSource
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('データ取得失敗', '住所情報データの取得に失敗しました')
        })
      }
    },

    loadArcives () {
      Promise.all([
        ArchiveData.fetchState({ targetUserID: this.timelineOwner.id }).then(res => {
          this.archiveLimited = res.limited
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('データ取得失敗', 'アーカイブリクエストデータの取得に失敗しました')
        }),
        ArchiveData.fetchList({ targetUserID: this.timelineOwner.id }).then(res => {
          this.archiveRequests = res.archive_datas
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('データ取得失敗', 'アーカイブデータの取得に失敗しました')
        })
      ]).then(() => {
        return true
      })
    },

    loadAppSigninTokens () {
      AppSigninToken.fetch({ targetUserID: this.timelineOwner.id, userNormal: 'YES' }).then(res => {
        this.appSigninTokens = res.appSigninTokens
        return this.appSigninTokens
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データ取得失敗', 'アプリログイン設定の取得に失敗しました')
      })
    },

    loadRegistedQrcodes () {
      StaticIdentifyCode.fetch({ targetUserID: this.timelineOwner.id }).then(res => {
        this.registedQrCodes = res.staticIdentifyCodes
        return this.registedQrCodes
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('データ取得失敗', '登録QRコードの取得に失敗しました')
      })
    },

    loadOmronConnectAccount () {
      if (process.env.VUE_APP_OMRON_CONNECT_AVAILABLE) {
        // fetch omron_connect_account status
        if (!this.omronConnectAccountAvailable) {
          OmronConnectAccount.fetch({ targetUserID: this.timelineOwner.id }).then(res => {
            if (res.omronConnectAccount) {
              this.omronConnectAccountAvailable = true
              return true
            } else {
              this.omronConnectAccountAvailable = false
              return false
            }
          })
        }
      } else {
        this.omronConnectAccountAvailable = false
        return true
      }
    },

    revokeOmronConnectAccount () {
      OmronConnectAccount.destroy({ targetUserID: this.timelineOwner.id }).then(res => {
      // this.$store.dispatch('destroyOmronConnectAccount', { targetUserID: this.timelineOwner.id }).then(res => {
        console.log(res)
        this.omronConnectAccountAvailable = false
        this.showNotifyDialog(
          'OMRON CONNECTアカウントの連携解除',
          'OMRON CONNECTアカウントの連携解除しました。\nナラティブブック上のOMRON CONNECTアカウント情報と、OMRON CONNECTから取得したバイタル情報をすべて削除しました。'
        )
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog(
          'OMRON CONNECTアカウントの連携解除失敗',
          'OMRON CONNECTアカウントの連携解除に失敗しました。'
        )
      })
    },

    addZoomUser () {
      if (process.env.VUE_APP_MEETING_AVAILABLE) {
        this.showZoomSigninNotify()
      }
    },

    reconnectZoomUser () {
      if (process.env.VUE_APP_MEETING_AVAILABLE) {
        this.showZoomSigninNotify()
      }
    },

    revokeZoomAuth () {
      if (process.env.VUE_APP_MEETING_AVAILABLE) {
        ZoomUser.destroy().then(() => {
          this.$emit('reloadZoomUserEvent')
          return true
        }).catch(err => {
          console.error(err.response)
        })
      }
    },

    showZoomSigninNotify () {
      if (process.env.VUE_APP_MEETING_AVAILABLE) {
        this.showConfirmDialog(
          'zoomアカウントの接続',
          'zoomアカウントの接続を行います。\nナラティブブックとzoomを連携させてビデオ会議を行うために、zoomのアカウントを指定してください。\n[はい]をクリックすると、zoomのログインページに遷移します。連携させるzoomアカウントでログインを行い、ナラティブブックを承認してください。',
          DIALOG_TYPE.YES_NO,
          () => {
            var zoomAuthURL = location.protocol + '//' + location.host + '/api/zoom_user/contact_zoom_auth'
            console.log('zoom auth url: ' + zoomAuthURL)
            window.location.href = zoomAuthURL
          }
        )
      }
    },

    showZoomAccountSwitchNotify () {
      if (process.env.VUE_APP_MEETING_AVAILABLE) {
        console.log('showZoomAccountSwitchNotify')
      }
    },

    loadNarrativePhoneAccount () {
      console.log('in mixin, loadNarrativePhoneAccount')
      NarrativePhoneAccount.api().fetchByAuthor({ author: this.timelineOwner.author }).then(res => {
        console.log('in mixin, loadNarrativePhoneAccount, fetchByAuthor')
        console.log(res)
        console.log(this.timelineOwner.id)
        console.log(NarrativePhoneAccount.query().where('user_id', this.timelineOwner.id).get()[0])
        this.narrativePhoneAccount = NarrativePhoneAccount.query().where('user_id', this.timelineOwner.id).get()[0]
        console.log(this.narrativePhoneAccount)
        return this.narrativePhoneAccount
      })
    },

    reloadPage () {
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },

    moveToTimeline () {
      this.$router.push('/maintimeline/' + this.timelineOwner.id)
    },

    newActivateInvitation () {
      this.showAcitivateInvitationModal = true
    },

    sendMailActivateInvitation () {
      this.showConfirmDialog(
        'ログイン案内の再送信',
        'ログイン案内を再送信します。\n本人確認コードの失敗回数はリセットされ、利用期限は現在から2日後にセットされます。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeSendMailAcitvateInvitation() }
      )
    },

    executeSendMailAcitvateInvitation () {
      AuthenticationActivateInvitation.sendMail({ targetUserID: this.timelineOwner.id }).then(() => {
      // this.$store.dispatch('sendMailAuthenticationActivateInvitation', { targetUserID: this.timelineOwner.id }).then(res => {
        this.showNotifyDialog('ログイン案内の再送信完了', 'ログイン案内を再送信しました。')
        return this.loadAuthenticationActivateInvitation()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('ログイン案内の再送信失敗', 'ログイン案内の再送信に失敗しました。')
      })
    },

    destroyActivateInvitation () {
      this.showConfirmDialog(
        'ログイン案内の取り消し',
        'ログイン案内を取り消します。\nすでに送信されたメールに記されたリンクは無効化されます。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeDestroyAcitvateInvitation() }
      )
    },

    executeDestroyAcitvateInvitation () {
      AuthenticationActivateInvitation.destroy({ targetUserID: this.timelineOwner.id }).then(() => {
        this.showNotifyDialog('ログイン案内の取り消し完了', 'ログイン案内を取り消ししました。')
        return this.loadAuthenticationActivateInvitation()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('ログイン案内の取り消し失敗', 'ログイン案内の取り消しに失敗しました。')
      })
    },

    cancelActivateInvitationSubmit (callback) {
      callback()
      this.showAcitivateInvitationModal = false
    },

    activateInvitationSubmit (formData, callback) {
      console.log('profile controll')
      AuthenticationActivateInvitation.create({ formData: formData }).then(() => {
        this.loadAuthenticationActivateInvitation()
        this.showAcitivateInvitationModal = false
        callback()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('ログイン案内の作成/送信失敗', 'ログイン案内の作成/送信に失敗しました。')
      })
    },

    changeMail () {
      this.showUserMailEditModal = true
    },

    cancelUserMailSubmit (callback) {
      console.log('cacel in profile view')
      this.showUserMailEditModal = false
      callback()
    },

    submitUserMailChange (formData) {
      User.acceptMailChange({formData: formData}).then(() => {
        this.showNotifyDialog('メールアドレス変更の受付', 'メールアドレスの変更を受け付けました。\n確認のメールが送信されていますので、変更手続きを完了させてください。')
        this.showUserMailEditModal = false
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('メールアドレス変更の失敗', 'メールアドレスの変更受け付けに失敗しました。')
      })
    },

    changePassword () {
      this.showPasswordUpdateModal = true
    },

    cancelPasswordUpdate () {
      this.showPasswordUpdateModal = false
    },

    showPasswordFormatNotify () {
      console.log('passwaord bad format notify')
      this.showNotifyDialog(
        'パスワートの形式が正しくありません',
        'パスワードに利用出来る文字は半角英数字と記号@._/-+()のみです。\n正しい文字で、8文字以上20文字以内に設定してください。'
      )
    },

    executePasswordUpdate (password, passwordConfirmation, expireAppTokens, callBack) {
      SigninUser.api().updatePassword({ targetUserID: this.timelineOwner.id, newPassword: password, passwordConfirmation: passwordConfirmation, expireAppTokens: expireAppTokens }).then(res => {
        if (res.success) {
          this.showPasswordUpdateModal = false
          this.showNotifyDialog(
            'パスワード更新',
            'パスワードが更新されました。'
          )
          callBack()
        } else {
          this.showPasswordUpdateModal = false
          this.showNotifyDialog(
            'パスワード更新失敗',
            'パスワードの更新に失敗しました。\nパスワードは変更されていません。'
          )
        }
      }).catch(error => {
        console.error(error)
        this.showPasswordUpdateModal = false
        this.showNotifyDialog(
          'パスワード更新失敗',
          'パスワードの更新に失敗しました。\nパスワードは変更されていません。'
        )
      })
    },

    editUserBasic () {
      this.showUserBasicEditModal = true
    },

    cancelUserBasicSubmit () {
      this.showConfirmDialog(
        '基本情報の編集キャンセル',
        '基本情報の編集をキャンセルします。\n編集した内容は全て破棄されます。よろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.showUserBasicEditModal = false
        }
      )
    },

    submitUserBasicForm (formData) {
      if (!formData) {
        this.showNotifyDialog(
          '内容の不備',
          '編集下内容に誤りがあります。もう一度内容を見直してください。'
        )
      } else {
        UserBasic.create({ formData: formData }).then(() => {
          // TODO: fire app event.
          console.log('in mixin/HorusProfieControll.js, submitUserBasicForm succss. gonna check native. native: ' + this.$native)
          if (this.$native) {
            console.log('in mixin/HorusProfieControll.js, submitUserBasicForm succss. gonna fire update event.')
            this.$native.event('userProfileUpdated', { updatedAt: new Date(), targetAuthorID: this.timelineOwner.author_id })
            console.log('in mixin/HorusProfieControll.js, submitUserBasicForm succss. fired update event.')
          }
          return this.reloadPage()
        }).catch(error => {
          console.error(error)
          this.showNotifyDialog(
            '更新失敗',
            '基本情報の更新に失敗しました',
            () => { this.showUserBasicEditModal = false }
          )
        })
      }
    },

    editUserAddress () {
      this.showUserAddressEditModal = true
    },

    cancelUserAddressSubmit () {
      this.showConfirmDialog(
        '住所情報の編集キャンセル',
        '住所情報の編集をキャンセルします。\n編集した内容は全て破棄されます。よろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.showUserAddressEditModal = false }
      )
    },

    submitUserAddressForm (formData) {
      if (!formData) {
        this.showNotifyDialog(
          '内容の不備',
          '編集下内容に誤りがあります。もう一度内容を見直してください。'
        )
      } else {
        UserAddress.create({ formData: formData }).then(() => {
          this.loadUserAddress()
          this.showUserAddressEditModal = false
        }).catch(error => {
          console.log(error)
          this.showNotifyDialog(
            '更新失敗',
            '基本情報の更新に失敗しました',
            () => { this.showUserAddressEditModal = false }
          )
        })
      }
    },

    archiveRequest (year, month) {
      var termStart = new Date(year, month + 1)
      var now = new Date()
      if (termStart > now) {
        this.showNotifyDialog('アーカイブ作成依頼出来ません', 'アーカイブの開始日に未来の日付は指定できません。')
      } else {
        var formData = new FormData()
        formData.append('target_user_id', this.timelineOwner.id)
        formData.append('archive_data[term_start]', termStart)
        ArchiveData.create({ formData: formData }).then(() => {
          this.showNotifyDialog('アーカイブ作成依頼完了', 'アーカイブ作成依頼を完了しました。システム運営側で受理された後、アーカイブデータが作成されます。\nお待ちください。')
          return this.loadArcives()
        }).catch(error => {
          console.error(error.response)
          this.showNotifyDialog('アーカイブ作成依頼失敗', 'アーカイブ作成依頼の作成に失敗しました')
        })
      }
    },

    registQrCode () {
      this.$emit('showQrReaderEvent', '新規QRコード登録', this.executeRegisterQR, true)
    },

    manualRegistQrCode () {
      this.$emit('showManualQrInputEvent', '新規QRコード登録', this.executeRegisterQR)
    },

    executeRegisterQR (decodeObject) {
      console.log('decoded-string: ' + decodeObject.decodedString + ' categoryID: ' + decodeObject.categoryID)
      var formData = new FormData()
      formData.append('identify_code', decodeObject.decodedString)
      if (decodeObject.categoryID && decodeObject.categoryID > 0) {
        formData.append('static_identify_code_category_id', decodeObject.categoryID)
      }
      StaticIdentifyCode.create({ formData: formData }).then(() => {
        this.showNotifyDialog('QRコードの登録完了', 'QRコードの登録を完了しました。')
        return this.loadRegistedQrcodes()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('QRコードの登録失敗', 'QRコードの登録に失敗しました。\n読み取ったQRが正しくないか、既に登録されているか、選択したカード種別が間違っている可能性があります。')
      })
    },

    deleteQrCode (qrCode) {
      this.showConfirmDialog(
        '登録QRコードの削除',
        '登録QRコードを削除します。\n削除してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeDeleteQrCode(qrCode) }
      )
    },

    executeDeleteQrCode (qrCode) {
      StaticIdentifyCode.destroy({ targetUserID: this.timelineOwner.id, targetID: qrCode.id }).then(() => {
        this.showNotifyDialog('QRコードの削除完了', 'QRコードを削除しました。')
        return this.loadRegistedQrcodes()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('QRコードの削除失敗', 'QRコードの削除に失敗しました。')
      })
    },

    removeAppSigninToken (token) {
      this.showConfirmDialog(
        'アプリ専用ログイン設定の利用停止',
        'アプリ専用ログイン設定を利用停止します。\n利用停止すると、設定されているアプリからの自動ログインが行えなくなります。\n停止してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeDestroyAppSigninToken(token) }
      )
    },

    executeDestroyAppSigninToken (token) {
      AppSigninToken.destroy({ targetUserID: this.timelineOwner.id, targetID: token.id }).then(() => {
        return this.loadAppSigninTokens()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('アプリ専用ログイン設定の削除失敗', 'アプリ専用ログイン設定の削除に失敗しました')
      })
    },

    quitNarrativeBook () {
      this.showConfirmDialog(
        'ナラティブブック退会の処理',
        'ナラティブブック退会の処理を始めます。\n退会処理にあたって、ログインパスワードが必要となります。この段階ではまだ退会処理は実行されません。\n続行しますか?',
        DIALOG_TYPE.YES_NO,
        () => { this.processQuitNarrativeBook() }
      )
    },

    processQuitNarrativeBook () {
      this.showRevokeProcessModal = true
      this.checkRevokeProcess = false
    },

    revokeChecked () {
      this.checkRevokeProcess = true
    },

    cancelRevoke () {
      this.showRevokeProcessModal = false
    },

    revokeUser (formData) {
      this.showConfirmDialog(
        'ナラティブブック退会実行',
        'ナラティブブックを退会します。\n「実行」を押すと、アカウントの削除を行います。\nこの操作は取り消すことが出来ません。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => {
          User.quitNarrativeBook({ formData: formData }).then(() => {
            // 後始末する
            // storeの中身全消去
            this.$store.dispatch('entities/deleteAll')
            // 退会完了ページへ遷移
            this.$router.push('/revoked')
          }).catch(error => {
            console.error(error.response)
            this.showNotifyDialog(
              'サーバーエラー',
              '退会処理に失敗しました。'
            )
            this.showRevokeProcessModal = false
          })
        }
      )
    },

    serverErrorOnRevoke () {
      this.showNotifyDialog(
        'サーバーエラー',
        'データの取得に失敗しました。'
      )
      this.showRevokeProcessModal = false
    }
  }
}
