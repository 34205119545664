<template>
  <div class="horus-address-entry-view">
    <h1 class="centering-title">
      <img src="/static/images/direct_message_icon.svg" />連絡先リスト
    </h1>
    <div class="jump-controll">
      <button class="show-direct-message-btn" @click="showDirectMessage">ダイレクトメッセージ表示</button>
    </div>
    <div class="address-entry-controll">
      <div class="list-controll">
        <button class="address-entry-display-btn" :class="{ active: displayContentType == 'address'}" @click="showEntryList">連絡先リスト</button>
      </div>
      <div class="request-controll">
        <button class="sent-request-display-btn" :class="{ active: displayContentType == 'sent'}" @click="showSentRequest">送信リクエスト</button>
        <button class="receive-request-display-btn" :class="{ active: displayContentType == 'receive'}" @click="showReceiveRequest">受信リクエスト</button>
      </div>
    </div>
    <div class="display-contents">
      <div class="content-address" v-show="displayContentType == 'address'">
        <div class="entry-display-controll">
          <span class="favorite-button" v-show="onlyFavorite" @click="toggleFavoriteDisplay"><img src="/static/images/favorite_on.svg"><span class="text">すべて表示</span></span>
          <span class="favorite-button" v-show="!onlyFavorite" @click="toggleFavoriteDisplay"><img src="/static/images/favorite_off.svg"><span class="text">お気に入りのみ表示</span></span>
          <button class="restore-entry-btn" @click="showRestoreTarget">連絡先の復元</button>
        </div>
        <div class="address-entry-list" v-show="onlyFavorite">
          <div class="title">お気に入り</div>
          <div class="entry-message" v-show="favoriteEntries.length < 1">(お気に入りの連絡先は有りません)</div>
          <div
            class="entry-line"
            v-for="favoEntry in favoriteEntries"
            :key="'favent' + favoEntry.id">
            <div class="favo-icon" @click="toggleFavorite(favoEntry.id)">
              <img src="/static/images/favorite_on.svg" v-if="favoEntry.favorite" />
              <img src="/static/images/favorite_off.svg" v-if="!favoEntry.favorite" />
            </div>
            <div class="author">
              <img :src="favoEntry.author.portrateSrc" class="author-portrate" v-if="favoEntry.author"/>
              <span class="name">{{ favoEntry.name }}</span>
            </div>
          </div><!-- favorite list -->
        </div>
        <div class="address-entry-list" v-show="!onlyFavorite">
          <div class="family-entries" v-if="!signinMember">
            <div class="title">家族</div>
            <div class="entry-message" v-show="familyEntries.length < 1">(家族の連絡先は有りません)</div>
            <div
              class="entry-line"
              v-for="familyEntry in familyEntries"
              :key="'fament' + familyEntry.id">
              <div class="favo-icon" @click="toggleFavorite(familyEntry)">
                <img src="/static/images/favorite_on.svg" v-if="familyEntry.favorite" />
                <img src="/static/images/favorite_off.svg" v-if="!familyEntry.favorite" />
              </div>
              <div class="author">
                <img :src="familyEntry.author.portrateSrc" class="author-portrate" v-if="familyEntry.author">
                <span class="name">{{ familyEntry.name }}</span>
              </div>
              <div class="controll">
                <button class="entry-delete-btn" @click="destroyEntry(familyEntry)">削除</button>
              </div>
            </div><!-- family list -->
          </div>
          <div class="other-entries">
            <div class="title">{{ otherTitle }}</div>
            <div class="entry-message" v-show="otherEntries.length < 1">(連絡先は有りません)</div>
            <div
              class="entry-line"
              v-for="otherEntry in otherEntries"
              :key="'othent' + otherEntry.id">
              <div class="favo-icon" @click="toggleFavorite(otherEntry)">
                <img src="/static/images/favorite_on.svg" v-if="otherEntry.favorite" />
                <img src="/static/images/favorite_off.svg" v-if="!otherEntry.favorite" />
              </div>
              <div class="author">
                <img :src="otherEntry.author.portrateSrc" class="author-portrate" v-if="otherEntry.author" />
                <span class="name">{{ otherEntry.name }}</span>
              </div>
              <div class="controll">
                <button class="entry-delete-btn" @click="destroyEntry(otherEntry)">削除</button>
              </div>
            </div><!-- other list -->
          </div>
          <div class="title">施設</div>
          <div
            class="organization-entry-line"
            v-for="orgEntry in organizationEntries"
            :key="'orgent' + orgEntry.id">
            <div class="title organization">{{ orgEntry.name }}</div>
            <div class="entry-line" v-if="orgEntry.exist">
              <div class="favo-icon" @click="toggleFavorite(orgEntry)">
                <img src="/static/images/favorite_on.svg" v-if="orgEntry.favorite" />
                <img src="/static/images/favorite_off.svg" v-if="!orgEntry.favorite" />
              </div>
              <div class="author">
                <span class="name">{{ orgEntry.name }} (代表)</span>
              </div>
              <div class="controll">
                <button class="entry-delete-btn" @click="destroyEntry(orgEntry)">削除</button>
              </div>
            </div>
            <div
              class="entry-line"
              v-for="suomEntry in orgEntry.entries"
              :key="'suoment'+ suomEntry.id">
              <div class="favo-icon" @click="toggleFavorite(suomEntry)">
                <img src="/static/images/favorite_on.svg" v-if="suomEntry.favorite" />
                <img src="/static/images/favorite_off.svg" v-if="!suomEntry.favorite" />
              </div>
              <div class="author">
                <img :src="suomEntry.author.portrateSrc" class="author-portrate" v-if="suomEntry.author" />
                <span class="name">{{ suomEntry.name }}</span>
              </div>
              <div class="controll">
                <button class="entry-delete-btn" @click="destroyEntry(suomEntry)">削除</button>
              </div>
            </div><!-- suom list -->
          </div><!-- organization list -->
        </div><!-- address entry list -->
      </div>
      <div class="content-sent" v-show="displayContentType == 'sent'">
        <h2>送信連絡先交換リクエスト</h2>
        <table class="sent-request-table">
          <tr><th>送信日時</th><th>送信先</th><th>状態</th></tr>
          <tr
            v-for="sentRequest in sentRequests"
            :key="'sentreq' + sentRequest.id">
            <td>{{ formTimeText(sentRequest.sent_at) }}</td>
            <td>
              <div class="receiver-author">
                <img :src="sentRequest.receiverAuthor.portrateSrc" class="author-portrate" v-if="sentRequest.receiverAuthor" />
                <span class="name" v-if="sentRequest.receiverAuthor">{{ sentRequest.receiverAuthor.name }}</span>
                <span class="name" v-if="sentRequest.receiverOrganization">{{ sentRequest.receiverOrganization.shortName }}(代表)</span>
              </div>
            </td>
            <td>{{ sentRequestState(sentRequest) }}</td>
          </tr>
        </table>
      </div><!-- sent requst list -->
      <div class="content-receive" v-show="displayContentType == 'receive'">
        <h2>受信連絡先交換リクエスト</h2>
        <table class="receive-request-table">
          <tr><th>送信日時</th><th>送信先</th><th>状態</th></tr>
          <tr
            v-for="receiveRequest in receiveRequests"
            :key="'receivereq' + receiveRequest.id">
            <td>{{ formTimeText(receiveRequest.direct_message.sent_at) }}</td>
            <td>
              <div class="sender-author">
                <img :src="receiveRequest.senderAuthor.portrateSrc" class="author-portrate" v-if="receiveRequest.senderAuthor" />
                <span class="name">{{ receiveRequest.direct_message.sender_name }}</span>
              </div>
            </td>
            <td>
              {{ receiveRequestState(receiveRequest) }}
              <button class="accept-btn" v-if="!receiveRequest.read_at" @click="acceptRequest(receiveRequest)">承認</button>
              <button class="reject-btn" v-if="!receiveRequest.read_at" @click="rejectRequest(receiveRequest)">拒否</button>
            </td>
          </tr>
        </table>
      </div><!-- receive request list -->
      <horus-footer />
    </div>
    <horus-address-entry-restorable-modal
      :modalDisplay="restorableModalDisplay"
      :restorableList="restorableList"
      v-on:restorableEntryEvent="restoreEntry"
      v-on:closeModalEvent="closeRestorables"/>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { AddressEntry } from '@/api'
import { Author, Organization } from '@/models'

import HorusAddressEntryRestorableModal from '@/components/addressEntry/HorusAddressEntryRestorableModal.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAddressEntry  from '@/mixin/HorusAddressEntry'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAddressEntry],

  name: 'HorusAddressEntryView',

  components: {
    HorusAddressEntryRestorableModal,
    HorusConfirmDialog,
    HorusFooter,
  },

  props: {
    loggedInUser: Object,
    signinMember: Object
  },

  data () {
    return {
      displayContentType: 'address',
      onlyFavorite: false,
      sentRequests: [],
      sentRequestCount: 0,
      sentRequestOffset: 0,
      receiveRequests: [],
      receiveRequestCount: 0,
      receiveRequestOffset: 0,
      restorableModalDisplay: false,
      restorableList: [],
    }
  },

  mounted () {
    this.initData()
  },

  watch: {
    addressEntries: { handler: 'classifyAddressEntries'}
  },

  computed: {
    otherTitle () {
      if (this.signinMember) {
        return 'かかりつけ利用者その他'
      } else {
        return 'その他の利用者'
      }
    },
  },

  methods: {
    initData () {
      this.loadAddressEntries()
      this.loadSentRequests()
      this.loadReceiveRequests()
    },

    async loadSentRequests (reset=false) {
      if (reset) {
        this.sentRequests = []
      }
      this.sentRequestOffset = this.sentRequests.length
      AddressEntry.fetchSentRequests({ offset: this.sentRequestOffset}).then(async res => {
        console.log(res)
        this.sentRequestCount = res.count
        this.sentRequests = res.direct_messages
        let unloadAuthors = []
        let unloadOrgs = []
        for(let sent of this.sentRequests) {
          console.log(sent.direct_message_receivers[0].receiver_author_id)
          if (sent.direct_message_receivers[0].receiver_author_id && sent.direct_message_receivers[0].receiver_author_id.length > 0) {
            if (!Author.find(sent.direct_message_receivers[0].receiver_author_id)) {
              unloadAuthors.push(sent.direct_message_receivers[0].receiver_author_id)
            }
          }
          if (sent.direct_message_receivers[0].organization_id) {
            if (!Organization.find(sent.direct_message_receivers[0].organization_id)) {
              unloadOrgs.push(sent.direct_message_receivers[0].organization_id)
            }
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ idList: unloadAuthors })
        }
        if (unloadOrgs.length > 0) {
          await Organization.api().fetchByID({ idList: unloadOrgs})
        }
        for(let sent of this.sentRequests) {
          if (sent.direct_message_receivers[0].receiver_author_id && sent.direct_message_receivers[0].receiver_author_id.length > 0) {
            sent.receiverAuthor = Author.find(sent.direct_message_receivers[0].receiver_author_id)
          }
          if (sent.direct_message_receivers[0].organization_id) {
            sent.receiverOrganization = Organization.find(sent.direct_message_receivers[0].organization_id)
          }
        }
      }).catch(error => { console.error(error) })
    },

    async loadReceiveRequests (reset=false) {
      if (reset) {
        this.receiveRequests = []
      }
      this.receiveRequestOffset = this.receiveRequests.length
      AddressEntry.fetchReceiveRequests({ offset: this.receiveRequestOffset}).then(async res => {
        console.log(res)
        this.receiveRequestCount = res.count
        this.receiveRequests = res.direct_message_receivers
        let unloadAuthors = []
        let unloadOrgs = []
        for(let recv of this.receiveRequests) {
          if (!Author.find(recv.direct_message.sender_author_id)) {
            unloadAuthors.push(recv.direct_message.sender_author_id)
          }
          if (recv.receiver_author_id && recv.receiver_author_id.length > 0) {
            if (!Author.find(recv.receiver_author_id)) {
              unloadAuthors.push(recv.receiver_author_id)
            }
          }
          if (recv.organization_id) {
            if (!Organization.find(recv.organization_id)) {
              unloadOrgs.push(recv.organization_id)
            }
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ idList: unloadAuthors })
        }
        if (unloadOrgs.length > 0) {
          await Organization.api().fetchByID({ idList: unloadOrgs})
        }
        for(let recv of this.receiveRequests) {
          recv.senderAuthor = Author.find(recv.direct_message.sender_author_id)
          if (recv.receiver_author_id && recv.receiver_author_id.length > 0) {
            recv.receiverAuthor = Author.find(recv.receiver_author_id)
          }
          if (recv.organization_id) {
            recv.receiverOrganization = Organization.find(recv.organization_id)
          }
        }
      }).catch(error => { console.error(error) })
    },

    showDirectMessage () {
      this.$router.push({path: '/direct_message'})
    },

    showEntryList () {
      console.log('show entry list')
      this.displayContentType = 'address'
      console.log(this.displayContentType)
    },

    showSentRequest () {
      console.log('show sent list')
      this.displayContentType = 'sent'
      console.log(this.displayContentType)
    },

    showReceiveRequest () {
      console.log('show receive list')
      this.displayContentType = 'receive'
      console.log(this.displayContentType)
    },

    toggleFavoriteDisplay () {
      this.onlyFavorite = !this.onlyFavorite
    },

    formTimeText (sent_at) {
      const sentAt = new Date(sent_at)
      return moment(sentAt).format('MM月DD日 HH:mm')
    },

    sentRequestState (sentRequest) {
      if (sentRequest.direct_message_receivers[0].read_at) {
        if (sentRequest.direct_message_receivers[0].response_text == 'YES') {
          return this.formTimeText(sentRequest.direct_message_receivers[0].read_at) + '承諾済み'
        } else {
          return this.formTimeText(sentRequest.direct_message_receivers[0].read_at) + '拒否済み'
        }
      } else {
        return '未読'
      }
    },

    receiveRequestState (receiveRequest) {
      if (receiveRequest.read_at) {
        if (receiveRequest.response_text === 'YES') {
          return this.formTimeText(receiveRequest.read_at) + '承諾済み'
        } else {
          return this.formTimeText(receiveRequest.read_at) + '拒否済み'
        }
      } else {
        return '未読'
      }
    },

    acceptRequest (receiveRequest) {
      this.showConfirmDialog(
        '連絡先の交換リクエストの承諾',
        '[' + receiveRequest.direct_message.sender_name + ']と相互に連絡先を登録します。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeAcceptRequest(receiveRequest)
        }
      )
    },

    executeAcceptRequest (receiveRequest) {
      AddressEntry.acceptExchange({ id: receiveRequest.id }).then(res => {
        console.log(res)
        this.showNotifyDialog(
          '連絡先の交換',
          '連絡先を交換しました。'
        )
        this.initData()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '連絡先の交換失敗',
          '連絡先の交換に失敗しました。'
        )
      })
    },

    rejectRequest (receiveRequest) {
      this.showConfirmDialog(
        '連絡先の交換リクエストの拒否',
        '[' + receiveRequest.direct_message.sender_name + ']からの連絡先交換リクエストを拒否します。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeRejectRequest(receiveRequest)
        }
      )
    },

    executeRejectRequest (receiveRequest) {
      AddressEntry.rejectExchange({ id: receiveRequest.id, responseText: '連絡先交換が拒否されました。' }).then(res => {
        console.log(res)
        this.showNotifyDialog(
          '連絡先の交換リクエストの拒否',
          '連絡先の交換リクエストを拒否しました。'
        )
        this.initData()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '連絡先リスト拒否失敗',
          '連絡先の交換リクエストの拒否に失敗しました。'
        )
      })
    },

    toggleFavorite (addressEntry) {
      console.log('on toggleFavorite, id: ' + addressEntry.id)
      if (addressEntry.favorite) {
        AddressEntry.toUnfavorite({ id: addressEntry.id }).then(res => {
          console.log(res)
          addressEntry.favorite = false
        }).catch(error => { console.error(error) })
      } else {
        AddressEntry.toFavorite({ id: addressEntry.id }).then(res => {
          console.log(res)
          addressEntry.favorite = true
        }).catch(error => { console.error(error) })
      }
    },

    destroyEntry (entry) {
      this.showConfirmDialog(
        '連絡先リストの削除',
        '連絡先リストの[' + entry.name + ']を削除します。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeDestroyEntry(entry)
        }
      )
    },

    executeDestroyEntry (entry) {
      AddressEntry.destroy({ targetID: entry.id }).then(res => {
        console.log(res)
        this.showNotifyDialog(
          '連絡先リストの削除',
          '連絡先リスト[' + entry.name + ']を削除しました。'
        )
        this.initData()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '連絡先リストの削除失敗',
          '連絡先リスト[' + entry.name + ']の削除に失敗しました。'
        )
      })
    },

    showRestoreTarget () {
      AddressEntry.fetchRestorable().then(res => {
        console.log(res)
        if (res.restorables && res.restorables.length > 0) {
          this.restorableList = res.restorables
          this.restorableModalDisplay = true
        } else {
          this.showNotifyDialog(
            '連絡先リストの復元',
            '復元可能な連絡先はありません。'
          )
        }
      })
    },

    restoreEntry (restorable) {
      this.restorableModalDisplay = false
      this.showConfirmDialog(
        '連絡先リストの復元',
        '連絡先リストに[' + restorable.target_name + ']を復元します。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeRestoreEntry(restorable)
        }
      )
    },

    executeRestoreEntry (restorable) {
      let formData = new FormData()
      if (restorable.target_user_id) {
        formData.append('target_user_id', restorable.target_user_id)
      }
      if (restorable.target_suom_id) {
        formData.append('target_suom_id', restorable.target_suom_id)
      }
      if (restorable.target_organization_id) {
        formData.append('target_organization_id', restorable.target_organization_id)
      }
      formData.append('target_type', restorable.target_type)
      AddressEntry.restoreEntry({ formData: formData }).then(res => {
        console.log(res)
        this.showNotifyDialog(
          '連絡先リストの復元',
          res.address_entry.name + 'を連絡先リストに復元しました。'
        )
        this.initData()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '連絡先リストの復元失敗',
          '連絡先リストの復元に失敗しました。'
        )
      })
    },

    closeRestorables () {
      this.restorableModalDisplay = false
    },

  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';

.horus-address-entry-view {
  height: calc(100% - 70px);
  overflow: auto;
  padding: 10px 50px;
  @include mediaQuery('phone') {
    padding: 10px;
    padding-bottom: 0;
  }
  .centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    img {
      width: 15px;
      margin-right: 3px;
    }
  }
  .jump-controll {
    .show-direct-message-btn {
      margin-bottom: 10px;
      cursor: pointer;
      padding: 2px 5px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .address-entry-controll {
    display: flex;
    justify-content: space-between;
    button {
      margin-bottom: 10px;
      cursor: pointer;
      padding: 2px 5px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      &.active {
        background-color: #aaa;
      }
    }
    .request-controll {
      button {
        margin-left: 10px;
      }
    }
  }
  .display-contents {
    .content-address {
      .entry-display-controll {
        display: flex;
        align-items: center;
        padding-bottom: 2px;
        border-bottom: 1px solid #aaa;
        .favorite-button {
          display: flex;
          align-content: center;
          img {
            width: 20px;
          }
          .text {
            margin-top: 2px;
          }
        }
        .restore-entry-btn {
          margin-left: 10px;
          cursor: pointer;
          padding: 2px 5px;
          border: 1px solid #666;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-size: 13px;
          font-weight: bold;
        }
      }
      .address-entry-list {
        .title {
          padding: 5px 0;
          font-weight: bold;
          border-bottom: 1px solid #aaa;
          &.organization {
            padding-left: 10px;
          }
        }
        .entry-line {
          padding: 5px 10px;
          display: flex;
          align-items: center;
          font-weight: normal;
          border-bottom: 1px solid #ddd;
          .author {
            display: flex;
            align-items: center
          }
          img {
            margin-top: 2px;
            margin-right: 5px;
            width: 15px;
          }
          .entry-delete-btn {
            margin-left: 15px;
            font-size: 0.7rem;
            font-weight: bold;
            background-color: $danger-color;
            border-radius: 5px;
            color: #fff;
          }
        }
      }
    }
    .content-sent {
      h2 {
        font-size: 1.1rem;
      }
      .sent-request-table {
        border-collapse: collapse;
        width: 100%;
        tr {
          th {
            border-bottom: 1px solid #ddd;
            text-align: left;
          }
          td {
            padding: 5px;
            border-bottom: 1px solid #ddd;
            vertical-align: middle;
            .receiver-author {
              display: flex;
              align-items: center;
              img {
                margin-right: 10px;
                width: 30px;
              }
            }
          }
        }
      }
    }
    .content-receive {
      h2 {
        font-size: 1.1rem;
      }
      .receive-request-table {
        border-collapse: collapse;
        width: 100%;
        tr {
          th {
            border-bottom: 1px solid #ddd;
            text-align: left;
          }
          td {
            padding: 5px;
            border-bottom: 1px solid #ddd;
            align-items: center;
            .sender-author {
              display: flex;
              align-items: center;
              img {
                margin-right: 10px;
                width: 30px;
              }
            }
            button {
              margin-left: 10px;
              cursor: pointer;
              padding: 2px 5px;
              border: 1px solid #666;
              border-radius: 3px;
              background-color: #666;
              color: #fff;
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>