<template>
  <div class="horus-create-debug-org-modal">
    <horus-modal-frame
      :showModalFlag="dummyDataModalDisplay">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />ダミーデータ生成</h1>
      </div>
      <div class="modal-body">
        <h4>施設情報</h4>
        <horus-attribute-in-form
          :labelText="'所属管理団体'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <div class="area-block-selection">
            <div class="area-block" @click="setTargetAreaBlock(null)">
              <horus-custom-check-box
                :active="!targetAreaBlock"
                :enabled="true"
                :type="'color'"
                />
              所属なし
            </div>
            <div class="area-block" v-for="areaBlock in areaBlocks" :key="'editOrgAB' + areaBlock.id" @click="setTargetAreaBlock(areaBlock)">
              <horus-custom-check-box
                :active="targetAreaBlock === areaBlock"
                :enabled="true"
                :type="'color'"
                />
              {{ areaBlock.name }}
            </div>
          </div>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'医療機関コード'"
          :necessary="false"
          :validationState="validateMedCode.state"
          :validationMessage="validateMedCode.message"
          :attributeNotify="''">
          <input type="text" size="12" v-model="editOrg.medicalInstitutionCode" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'名称'"
          :necessary="true"
          :validationState="validateName.state"
          :validationMessage="validateName.message"
          :attributeNotify="''">
          <input type="text" size="50" v-model="editOrg.name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'ヨミガナ'"
          :necessary="true"
          :validationState="validateNameKana.state"
          :validationMessage="validateNameKana.message"
          :attributeNotify="'カタカナのみ'">
          <input type="text" size="50" v-model="editOrg.nameKana" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'略称'"
          :necessary="true"
          :validationState="validateShortName.state"
          :validationMessage="validateShortName.message"
          :attributeNotify="''">
          <input type="text" size="30" v-model="editOrg.shortName" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'郵便番号'"
          :necessary="true"
          :validationState="validateZipCode.state"
          :validationMessage="validateZipCode.message"
          :attributeNotify="''">
          <input type="text" size="10" v-model="editOrg.zipCode" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'都道府県'"
          :necessary="true"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="''">
          <select class="sex-type-select" v-model="editOrg.prefecture">
            <option v-for="prefCode in addressData.PREFECTURE_CODE_JP" :key="'prefectureSelect' + prefCode[0]" :value="prefCode[0]">{{ prefCode[1] }}</option>
          </select>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'市町村区'"
          :necessary="true"
          :validationState="validateCity.state"
          :validationMessage="validateCity.message"
          :attributeNotify="''">
          <input type="text" v-model="editOrg.city" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'住所詳細'"
          :necessary="false"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="''">
          <input type="text" size="50" v-model="editOrg.addressDetail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'電話番号'"
          :necessary="true"
          :validationState="validateTel.state"
          :validationMessage="validateTel.message"
          :attributeNotify="'ハイフン区切り半角数字: 000-000-0000'">
          <input type="text" size="15" v-model="editOrg.tel" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="validateMail.state"
          :validationMessage="validateMail.message"
          :attributeNotify="''">
          <input type="text" size="40" v-model="editOrg.mail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'施設種別'"
          :necessary="true"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="''">
          <select class="sex-type-select" v-model="editOrg.organizationType">
            <option v-for="(orgType, index) in orgData.ORGANIZATION_TYPES" :key="'orgTypeSelect' + index" :value="index">{{ orgType }}</option>
          </select>
        </horus-attribute-in-form>
        <h4>施設管理者情報</h4>
        <div class="form-list">
          <horus-attribute-in-form
            :labelText="'NBID'"
            :necessary="true"
            :validationState="validateNBID"
            :validationMessage="validateNBIDMessage"
            :attributeNotify="'６文字以上 半角英小文字、半角数字、一部の記号@._/-+() （使用できる記号は、@._/-+()の８種類のみです。）'">
            <input type="text" v-model="nbid" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'メールアドレス'"
            :necessary="true"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <input type="text" v-model="managerEmail" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'パスワード'"
            :necessary="true"
            :validationState="validatePassword"
            :validationMessage="validatePasswordMessage"
            :attributeNotify="'8文字以上'">
            <input :type="passwordInputType" v-model="password" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'パスワード(確認)'"
            :necessary="true"
            :validationState="validatePasswordConf"
            :validationMessage="validatePasswordConfMessage"
            :attributeNotify="'上と同じパスワードを入力'">
            <input :type="passwordInputType" v-model="passwordConfirmation" />
          </horus-attribute-in-form>
          <div class="toggle-password-visible" @click="togglePasswordVisible">
            <horus-custom-check-box
              :active="passwordVisible"
              :enabled="true"
              :type="'color'"
              />パスワードを表示
          </div>
        </div>
        <h4 class="new_account_heading">基本情報</h4>
        <div class="form-list">
          <horus-user-basic-form-inputs ref="userBasicForm" />
        </div>
        <h4 class="new_account_heading">連絡先等</h4>
        <div class="form-list">
          <horus-user-address-form-inputs ref="userAddressForm" />
        </div>
        <h4>自動生成職員データ設定</h4>
        <div class="form-list">
          <horus-attribute-in-form
            :labelText="'BASE_NBID'"
            :necessary="true"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="'６文字以上 半角英小文字、半角数字、一部の記号@._/-+() （使用できる記号は、@._/-+()の８種類のみです。）'">
            <input type="text" v-model="baseMemberNbid" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'生成するデータ数'"
            :necessary="true"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <input type="text" v-model="createMemberCount" />
          </horus-attribute-in-form>
        </div>
        <h4>自動生成利用者データ設定</h4>
        <div class="form-list">
          <horus-attribute-in-form
            :labelText="'BASE_NBID'"
            :necessary="true"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="'６文字以上 半角英小文字、半角数字、一部の記号@._/-+() （使用できる記号は、@._/-+()の８種類のみです。）'">
            <input type="text" v-model="baseRelNbid" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'生成するデータ数'"
            :necessary="true"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <input type="text" v-model="createRelCount" />
          </horus-attribute-in-form>
        </div>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submitCreate">一括追加</button>
        </div>
      </div><!-- modal body -->
    </horus-modal-frame>
  </div>
</template>

<script>
import { AreaBlock } from '@/models'
import { User, SysteManageUtils } from '@/api'

import HorusUserBasicFormInputs from '@/components/profile/HorusUserBasicFormInputs.vue'
import HorusUserAddressFormInputs from '@/components/profile/HorusUserAddressFormInputs.vue'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import AuthenticateInfo from '@/validateModel/AuthenticateInfo.js'

import * as USER_ADDRESS from '@/assets/constants/addressData.js'
import * as ORGANIZATION_TYPES from '@/assets/constants/organizationType.js'

export default {
  name: 'HorusCreateDebugOrgModal',

  components: {
    HorusUserBasicFormInputs,
    HorusUserAddressFormInputs,
    HorusAttributeInForm,
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    dummyDataModalDisplay: Boolean,
  },

  data () {
    return {
      editOrg: {},
      addressData: USER_ADDRESS,
      orgData: ORGANIZATION_TYPES,
      areaBlocks: [],
      targetAreaBlock: null,
      nbid: '',
      nbidState: '',
      managerEmail: '',
      password: '',
      passwordConfirmation: '',
      passwordVisible: false,
      baseMemberNbid: '',
      createMemberCount: 0,
      baseRelNbid: '',
      createRelCount: 0
    }
  },

  async mounted () {
    await this.loadAreaBlocks()
    this.initData()
  },

  watch: {
    nbid: { handler: 'updateNBIDState' }
  },

  computed: {
    areaBlockName () {
      if (this.targetAreaBlock) {
        return this.targetAreaBlock.name
      }
      return '所属なし'
    },

    validateMedCode () {
      var valid = true
      var state = 'disabled'
      var message = ''
      if (this.editOrg.medicalInstitutionCode) {
        var trimed = this.editOrg.medicalInstitutionCode.trim()
        if (trimed.length > 0) {
          if (trimed.match(/^\d{10}$/)) {
            state = 'valid'
          } else {
            valid = false
            state = 'invalid'
            message = '医療機関コードは半角数字で10桁です。'
          }
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateName () {
      var checkResult = this.stringLengthCheck(this.editOrg.name, 127, '施設名称')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateNameKana () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.editOrg.nameKana) {
        message = 'ヨミガナは入力必須です。1文字以上126文字以下で入力ください。'
      } else {
        var trimed = this.editOrg.nameKana.trim()
        if (trimed.match(/^[ァ-ヶー\u{3000}\s]+$/)) {
          if (trimed.length > 0 && trimed.length <= 127) {
            valid = true
            state = 'valid'
          } else if (trimed.length > 127) {
            message = 'ヨミガナは20文字以内で入力ください。'
          } else {
            message = 'ヨミガナは1文字以上126文字以下で入力ください。'
          }
        } else {
          message = 'ヨミガナは全角カタカナ以外の文字は使えません。'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateShortName () {
      var checkResult = this.stringLengthCheck(this.editOrg.shortName, 20, '施設略称')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateZipCode () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.editOrg.zipCode) {
        message = '郵便番号は入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
      } else {
        var trimed = this.editOrg.zipCode.trim()
        if (trimed.match(this.addressData.ZIP_CODE_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '郵便番号はハイフン区切りの正しい形式で入力してください。(例:000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateCity () {
      var checkResult = this.stringLengthCheck(this.editOrg.city, 127, '市町村区')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateTel () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.editOrg.tel) {
        message = '電話雁号は入力必須です。'
      } else {
        var trimed = this.editOrg.tel.trim()
        if (trimed.match(this.addressData.TEL_NUMBER_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '電話番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateMail () {
      var checkResult = this.stringLengthCheck(this.editOrg.mail.length, 127, 'メールアドレス')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateNBID () {
      if (this.nbidState === 'available') {
        return 'valid'
      } else if (this.nbidState === 'alreadyuse' || this.nbidState === 'invalid') {
        return 'invalid'
      } else {
        return 'disabled'
      }
    },

    validateNBIDMessage () {
      if (this.nbidState === 'alreadyuse') {
        return '入力されたNBIDは、すでに別のユーザーが利用しています。別のNBIDを設定してください。'
      } else if (this.nbidState === 'invalid') {
        return '入力されたNBIDは、形式が正しくありません。6文字以上で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    validatePassword () {
      if (this.password.length <= 0) {
        return 'disabled'
      } else if (AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordMessage () {
      if (this.password.length > 0 && !AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'パスワードは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    validatePasswordConf () {
      if (this.passwordConfirmation.length <= 0) {
        return 'disabled'
      } else if (this.password.trim() === this.passwordConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordConfMessage () {
      if (this.password.length > 0 && (this.password.trim() !== this.passwordConfirmation)) {
        return '上で入力したパスワードと同じ値を入れてください。'
      } else {
        return ''
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    allValid () {
      if (
        this.validateMedCode.valid &&
        this.validateName.valid &&
        this.validateNameKana.valid &&
        this.validateShortName.valid &&
        this.validateZipCode.valid &&
        this.validateCity.valid &&
        this.validateTel.valid &&
        this.validateMail.valid &&
        this.nbidState === 'available' &&
        this.validatePassword === 'valid' &&
        this.validatePasswordConf === 'valid' &&
        this.$refs.userBasicForm.userBasic.isValid() &&
        this.$refs.userAddressForm.userAddress.isValid() &&
        this.baseMemberNbid.length > 6 &&
        this.baseRelNbid.length > 6 &&
        this.createRelCount > 0
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    stringLengthCheck (value, length, valueName) {
      var valid = false
      var message = ''
      if (!value || value.length <= 0) {
        message = valueName + 'は入力必須です。'
      } else if (value.length > length) {
        message = valueName + 'は' + length + '文字以下で入力ください。'
      } else {
        valid = true
      }
      return { valid: valid, message: message }
    },

    initData () {
      this.initEditOrg()
    },

    initEditOrg () {
      console.log('initEditOrg')
      this.editOrg = {
        medicalInstitutionCode: '',
        name: '',
        nameKana: '',
        shortName: '',
        zipCode: '',
        prefecture: '00',
        city: '',
        address: '',
        tel: '',
        mail: '',
        organizationType: this.orgData.ORGANIZATION_TYPES.length - 1
      }
      this.targetAreaBlock = null
    },

    loadAreaBlocks () {
      AreaBlock.api().fetchList().then(res => {
        this.areaBlocks = res.area_blocks
        if (this.sourceOrganization && this.sourceOrganization.area_block_id && !this.targetAreaBlock) {
          this.targetAreaBlock = this.areaBlocks.find(areablock => areablock.id === this.sourceOrganization.area_block_id)
        } else if (this.areaBlockOperator && !this.sourceOrganization) {
          this.targetAreaBlock = this.areaBlocks.find(areablock => areablock.id === this.areaBlockOperator.area_block_id)
        }
      })
    },

    setTargetAreaBlock (areaBlock) {
      this.targetAreaBlock = areaBlock
    },

    updateNBIDState () {
      console.log('updateNBIDState')
      if (this.nbid.length <= 0) {
        this.nbidState = 'none'
      } else if (this.nbid.trim().match(/^([a-z0-9]|@|\.|_|\/|-|\+|\(|\)){6,120}$/g)) {
        // get valid
        User.nbidValidation({ nbid: this.nbid }).then(res => {
        // this.$store.dispatch('nbidValidation', { nbid: this.nbid }).then(res => {
          this.nbidState = res.result
        })
      } else {
        this.nbidState = 'invalid'
      }
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    cancelSubmit () {
      this.$emit('cancelEvent')
    },

    submitCreate () {
      if (this.allValid) {
        var userBasic = this.$refs.userBasicForm.userBasic
        var userAddress = this.$refs.userAddressForm.userAddress
        var portrate = this.$refs.userBasicForm.portrateObj
        var formData = new FormData()
        if (this.targetAreaBlock) {
          formData.append('organization[area_block_id]', this.targetAreaBlock.id)
        }
        formData.append('organization[name]', this.editOrg.name)
        formData.append('organization[name_kana]', this.editOrg.nameKana)
        formData.append('organization[short_name]', this.editOrg.shortName)
        formData.append('organization[prefecture]', this.editOrg.prefecture)
        formData.append('organization[city]', this.editOrg.city)
        formData.append('organization[address]', this.editOrg.addressDetail)
        formData.append('organization[zip_code]', this.editOrg.zipCode)
        formData.append('organization[tel]', this.editOrg.tel)
        formData.append('organization[mail]', this.editOrg.mail)
        formData.append('organization[organization_type_id]', this.editOrg.organizationType)
        formData.append('user[name]', this.nbid)
        formData.append('user[mail]', this.managerEmail)
        formData.append('authenticate_info[password]', this.password)
        formData.append('authenticate_info[password_confirmation]', this.passwordConfirmation)
        formData.append('user_basic[family_name]', userBasic.familyName)
        formData.append('user_basic[given_name]', userBasic.givenName)
        formData.append('user_basic[family_name_kana]]', userBasic.familyNameKana)
        formData.append('user_basic[given_name_kana]', userBasic.givenNameKana)
        formData.append('user_basic[sex_type]', userBasic.sexType)
        formData.append('user_basic[birth_date]', userBasic.birthDate)
        formData.append('user_basic[blood_type_abo]', userBasic.bloodTypeABO)
        formData.append('user_basic[blood_type_rh]', userBasic.bloodTypeRH)
        if (portrate) {
          formData.append('user_basic[portrate]', portrate.uploadFile, portrate.name)
        }
        formData.append('user_address[zip_code]', userAddress.zipCode)
        formData.append('user_address[prefecture]', userAddress.prefecture)
        formData.append('user_address[city]', userAddress.city)
        formData.append('user_address[address]', userAddress.address)
        formData.append('user_address[tel]', userAddress.tel)
        formData.append('user_address[cellphone]', userAddress.cellphone)
        formData.append('user_address[fax]', userAddress.fax)
        formData.append('user_address[mail]', userAddress.mail)
        formData.append('nb_rule_accept', this.admitAgreement)
        formData.append('create_member_count', this.createMemberCount)
        formData.append('fake_member_base_name', this.baseMemberNbid)
        formData.append('create_user_count', this.createRelCount)
        formData.append('fake_user_base_name', this.baseRelNbid)
        SysteManageUtils.createDummyData({ formData: formData }).then(res => {
          console.log(res)
          this.$emit('createDummyFinishEvent', res)
        }).catch(error => {
          console.log(error.response)
          this.$emit('createDummyFinishEvent', error)
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.horus-create-debug-org-modal {
  .modal-body {
    padding: 10px 20px;
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>