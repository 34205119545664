<template>
  <div class="horus-manage-area-block-form-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1>{{ modalTitle }}</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'名称'"
          :necessary="true"
          :validationState="validateName.state"
          :validationMessage="validateName.message"
          :attributeNotify="''" v-if="!sourceAreaBlock">
          <input type="text" size="50" v-model="areaBlock.name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'ヨミガナ'"
          :necessary="true"
          :validationState="validateNameKana.state"
          :validationMessage="validateNameKana.message"
          :attributeNotify="'カタカナのみ'">
          <input type="text" size="50" v-model="areaBlock.name_kana" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'略称'"
          :necessary="true"
          :validationState="validateShortName.state"
          :validationMessage="validateShortName.message"
          :attributeNotify="''">
          <input type="text" size="30" v-model="areaBlock.short_name" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'略称カナ'"
          :necessary="true"
          :validationState="validateShortNameKana.state"
          :validationMessage="validateShortNameKana.message"
          :attributeNotify="''">
          <input type="text" size="30" v-model="areaBlock.short_name_kana" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'郵便番号'"
          :necessary="true"
          :validationState="validateZipCode.state"
          :validationMessage="validateZipCode.message"
          :attributeNotify="''">
          <input type="text" size="10" v-model="areaBlock.zip_code" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'都道府県'"
          :necessary="true"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="''">
          <select class="sex-type-select" v-model="areaBlock.prefecture">
            <option v-for="prefCode in addressData.PREFECTURE_CODE_JP" :key="'prefectureSelect' + prefCode[0]" :value="prefCode[0]">{{ prefCode[1] }}</option>
          </select>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'市町村区'"
          :necessary="true"
          :validationState="validateCity.state"
          :validationMessage="validateCity.message"
          :attributeNotify="''">
          <input type="text" v-model="areaBlock.city" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'住所詳細'"
          :necessary="false"
          :validationState="'disabled'"
          :validationMessage="''"
          :attributeNotify="''">
          <input type="text" size="50" v-model="areaBlock.address" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'電話番号'"
          :necessary="true"
          :validationState="validateTel.state"
          :validationMessage="validateTel.message"
          :attributeNotify="'ハイフン区切り半角数字: 000-000-0000'">
          <input type="text" size="15" v-model="areaBlock.tel" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="validateMail.state"
          :validationMessage="validateMail.message"
          :attributeNotify="''">
          <input type="text" size="40" v-model="areaBlock.mail" />
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">{{ submitString }}</button>
        </div>
      </div><!-- modal body -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import * as USER_ADDRESS from '@/assets/constants/addressData.js'

export default {
  name: 'HorusManageAreaBlockFormModal',

  components: {
    HorusAttributeInForm,
    HorusModalFrame
  },

  props: {
    sourceAreaBlock: Object,
    showModal: Boolean
  },

  data () {
    return {
      areaBlock: {},
      addressData: USER_ADDRESS,
      iconimageObj: null
    }
  },

  watch: {
    sourceAreaBlock: { handler: 'initData', immediate: true }
  },

  computed: {
    modalTitle () {
      if (this.sourceAreaBlock) {
        return '管理団体編集'
      } else {
        return '新規管理団体作成'
      }
    },

    submitString () {
      if (this.sourceAreaBlock) {
        return '更新'
      } else {
        return '新規作成'
      }
    },

    validateName () {
      var checkResult = this.stringLengthCheck(this.areaBlock.name, 127, '管理団体名称')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateNameKana () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.areaBlock.name_kana) {
        message = 'ヨミガナは入力必須です。1文字以上126文字以下で入力ください。'
      } else {
        var trimed = this.areaBlock.name_kana.trim()
        if (trimed.match(/^[ァ-ヶー\u{3000}\s]+$/)) {
          if (trimed.length > 0 && trimed.length <= 127) {
            valid = true
            state = 'valid'
          } else if (trimed.length > 127) {
            message = 'ヨミガナは20文字以内で入力ください。'
          } else {
            message = 'ヨミガナは1文字以上126文字以下で入力ください。'
          }
        } else {
          message = 'ヨミガナは全角カタカナ以外の文字は使えません。'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateShortName () {
      var checkResult = this.stringLengthCheck(this.areaBlock.short_name, 20, '施設略称')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateShortNameKana () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.areaBlock.short_name_kana) {
        message = '略称ヨミガナは入力必須です。1文字以上40文字以下で入力ください。'
      } else {
        var trimed = this.areaBlock.short_name_kana.trim()
        if (trimed.match(/^[ァ-ヶー\u{3000}\s]+$/)) {
          if (trimed.length > 0 && trimed.length <= 40) {
            valid = true
            state = 'valid'
          } else if (trimed.length > 127) {
            message = '略称ヨミガナは20文字以内で入力ください。'
          } else {
            message = '略称ヨミガナは1文字以上40文字以下で入力ください。'
          }
        } else {
          message = '略称ヨミガナは全角カタカナ以外の文字は使えません。'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateZipCode () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.areaBlock.zip_code) {
        message = '郵便番号は入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
      } else {
        var trimed = this.areaBlock.zip_code.trim()
        if (trimed.match(this.addressData.ZIP_CODE_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '郵便番号はハイフン区切りの正しい形式で入力してください。(例:000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateCity () {
      var checkResult = this.stringLengthCheck(this.areaBlock.city, 127, '市町村区')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    validateTel () {
      var valid = false
      var state = 'invalid'
      var message = ''
      if (!this.areaBlock.tel) {
        message = '電話雁号は入力必須です。'
      } else {
        var trimed = this.areaBlock.tel.trim()
        if (trimed.match(this.addressData.TEL_NUMBER_REGXP)) {
          valid = true
          state = 'valid'
        } else {
          message = '電話番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
        }
      }
      return { valid: valid, state: state, message: message }
    },

    validateMail () {
      var checkResult = this.stringLengthCheck(this.areaBlock.mail.length, 127, 'メールアドレス')
      if (checkResult.valid) {
        return { valid: true, state: 'valid', message: checkResult.message }
      } else {
        return { valid: false, state: 'invalid', message: checkResult.message }
      }
    },

    allValid () {
      if (
        this.validateName.valid &&
        this.validateNameKana.valid &&
        this.validateShortName.valid &&
        this.validateShortNameKana.valid &&
        this.validateZipCode.valid &&
        this.validateCity.valid &&
        this.validateTel.valid &&
        this.validateMail.valid
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    stringLengthCheck (value, length, valueName) {
      var valid = false
      var message = ''
      if (!value || value.length <= 0) {
        message = valueName + 'は入力必須です。'
      } else if (value.length > length) {
        message = valueName + 'は' + length + '文字以下で入力ください。'
      } else {
        valid = true
      }
      return { valid: valid, message: message }
    },

    initData () {
      if (this.sourceAreaBlock) {
        this.areaBlock.name = this.sourceAreaBlock.name
        this.areaBlock.name_kana = this.sourceAreaBlock.name_kana
        this.areaBlock.short_name = this.sourceAreaBlock.short_name
        this.areaBlock.short_name_kana = this.sourceAreaBlock.short_name_kana
        this.areaBlock.zip_code = this.sourceAreaBlock.zip_code
        this.areaBlock.prefecture = this.sourceAreaBlock.prefecture
        this.areaBlock.city = this.sourceAreaBlock.city
        this.areaBlock.address = this.sourceAreaBlock.address
        this.areaBlock.tel = this.sourceAreaBlock.tel
        this.areaBlock.mail = this.sourceAreaBlock.mail
        this.iconimageObj = null
      } else {
        this.initAreaBlock()
        this.iconimageObj = null
      }
    },

    initAreaBlock () {
      this.areaBlock = {
        name: '',
        name_kana: '',
        short_name: '',
        short_name_kana: '',
        zip_code: '',
        prefecture: '00',
        city: '',
        address: '',
        tel: '',
        mail: ''
      }
    },

    cancelSubmit () {
      this.$emit('submitCancelEvent', () => { this.initData() })
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        if (this.sourceAreaBlock) {
          formData.append('id', this.sourceAreaBlock.id)
        }
        formData.append('area_block[name]', this.areaBlock.name)
        formData.append('area_block[name_kana]', this.areaBlock.name_kana)
        formData.append('area_block[short_name]', this.areaBlock.short_name)
        formData.append('area_block[short_name_kana]', this.areaBlock.short_name_kana)
        formData.append('area_block[prefecture]', this.areaBlock.prefecture)
        formData.append('area_block[city]', this.areaBlock.city)
        formData.append('area_block[address]', this.areaBlock.address)
        formData.append('area_block[zip_code]', this.areaBlock.zip_code)
        formData.append('area_block[tel]', this.areaBlock.tel)
        formData.append('area_block[mail]', this.areaBlock.mail)
        this.$emit('submitEvent', formData, () => { this.initData() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-manage-area-block-form-modal {
  .modal-body {
    padding: 10px 20px;
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
