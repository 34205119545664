<template>
<div class="timeline-record-wrapper" :id="'timelineRecord' + timelineRecord.id">
  <div class="timeline-record-dummy-block" v-if="!timelineRecord.record">
    <div class="dummy-header">
      <div class="dummy-portrate">&nbsp;</div>
      <div class="dummy-title-block">
        <div class="dummy-title-line"></div>
        <div class="dummy-sub-line"></div>
      </div>
    </div>
    <div class="dummy-body-block">
      <div class="dummy-body-line">&nbsp;</div>
      <div class="dummy-body-line">&nbsp;</div>
      <div class="dummy-body-line">&nbsp;</div>
    </div>
  </div>
  <div class="timeline-record-block" ref="toplevelBlock" :id="'timelineRecord!!' + timelineRecord.id" v-if="timelineRecord.record">
    <div class="record-header">
      <horus-record-head-info
        :timelineOwner="timelineOwner"
        :author="timelineRecord.record.author"
        :targetAt="timelineRecord.record.targetAt"
        v-on:modalOpenEvent="showAuthorInfo" />
      <div class="header-icons">
        <div class="private-record" v-if="timelineRecord.record.private"><img src="/static/images/close_lock.png"/></div>
        <div class="quated-record" v-if="timelineRecord.record.quated_record_id" @click="showQuateThread" ><img src="/static/images/talk_icon.png" /></div>
      </div>
    </div>
    <div class="record-body-box">
      <horus-record-body
        :timelineRecord="timelineRecord.record"
        :key="'recordBody' + timelineRecord.id"
        v-on:durgEntryPhotoClickEvent="showDrugEntryPhoto"
        v-on:timelinePhotoClickEvent="showPhoto"
        v-on:timelineFileClickEvent="openFile"
        v-on:videoContentClickEvent="showVideo"
        v-on:audioContentClickEvent="showAudio" />
    </div>
    <div class="record-footer">
      <div class="record-tags">
        <div class="record-hashtag"  v-for="hashTag in timelineRecord.record.hashTags" :key="hashTag.id" @click="narrowDownByTag(hashTag)">
          <horus-hash-tag-display :hashTag="hashTag" :sizeType="'small'"/>
        </div>
      </div>
      <div class="record-footer-info">
        <div class="footer-left">
          <div class="record-thanks" v-if="!isReportRecord">
            <div class="thanks" v-if="timelineRecord.record.thanks_authors && timelineRecord.record.thanks_authors.length > 0" @click="showThanksAuthors($event)" v-click-outside="closeThanksAuthors">
              <img src="/static/images/thanks_on.png" />
              <div class="thanks-authors" v-show="showThanks">
                <div class="thank-authors-content" v-html="thanksAuthorMessage"></div>
                <div class="add-thanks" v-if="sigininUserDontThanks" @click="addThanks" >ありがとうをする</div>
                <div class="remove-thanks-bloack" :class="{hide: sigininUserDontThanks}" @click="removeThanks">
                  <img src="/static/images/batsu_icon.png" />ありがとうを取り消す
                </div>
              </div>
            </div>
            <div v-if="sigininUserDontThanks" class="thanks" :class="{disable: !thanksAvaliable}" @click="addThanks"><img src="/static/images/thanks_off.png" /></div>
          </div>
          <div class="record-nudged" v-show="!isReportRecord && timelineRecord.record.nudged" @click="confirmDeleteNudge" v-click-outside="closeConfirmDeleteNudge">
            <img src="/static/images/nudge_on.png" />
            <div class="delete-nudge-confirm" v-show="!isReportRecord && deleteNudgeConfirmBoxShow" @click="deleteNudge">nudgeを取り消す</div>
          </div>
          <div class="record-nudged" v-show="!isReportRecord && !timelineRecord.record.nudged" @click="nudgeRecord">
            <img src="/static/images/nudge_off.png" />
          </div>
          <div class="quated-records" v-if="timelineRecord.record.quateRecordsCount" @click="showReplyRecords">
            {{ timelineRecord.record.quateRecordsCount }}件の返信
          </div>
        </div><!-- footer left end -->
        <div class="footer-right">
          <button type="button" class="quote-btn" v-if="!isReportRecord" @click="quoteRecord">引用</button>
          <button type="button" class="reply-btn" v-if="!isReportRecord" @click="setReplyRecord">返信</button>
          <button type="button" class="destroy-btn" @click="destroyRecord" v-if="deletable">
            <img src="/static/images/bucket_icon.png" />
          </button>
          <button type="button" class="edit-btn" @click="editRecord">
            <img src="/static/images/pencil_icon.png" />
          </button>
        </div>
      </div><!-- record footer info end -->
    </div>
  </div>
  <div class="last-watch-at" v-if="showLastWatchAt">{{ lastWatchAtStr }}</div>
  <div class="month-splitter" v-if="monthChange">{{ monthSplitText }}</div>
</div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
// import vClickOutside from 'click-outside-vue3'
import moment from 'moment'

import HorusHashTagDisplay from '@/components/common/HorusHashTagDisplay.vue'
import HorusRecordHeadInfo from '@/components/timelineRecord/HorusRecordHeadInfo.vue'
import HorusRecordBody from '@/components/timelineRecord/HorusRecordBody.vue'

export default {
  name: 'HorusTimelineRecord',

  components: {
    HorusHashTagDisplay,
    HorusRecordHeadInfo,
    HorusRecordBody
  },

  props: {
    timelineOwner: Object,
    timelineRecord: Object,
    loggedInAuthor: Object,
    nextRecord: Object,
    lastWatchAt: Date
  },

  data () {
    return {
      showThanks: false,
      currentOpenAuthor: '',
      deleteNudgeConfirmBoxShow: false
    }
  },

  computed: {
    monthChange () {
      if (this.nextRecord && this.nextRecord.record && this.timelineRecord.record) {
        return (this.timelineRecord.record.targetAt.getYear() !== this.nextRecord.record.targetAt.getYear() || this.timelineRecord.record.targetAt.getMonth() !== this.nextRecord.record.targetAt.getMonth())
      }
      return false
    },

    monthSplitText () {
      if (this.nextRecord && this.nextRecord.record) {
        return this.nextRecord.record.targetAt.getFullYear() + '年' + (this.nextRecord.record.targetAt.getMonth() + 1) + '月'
      }
      return ''
    },

    showLastWatchAt () {
      if (this.lastWatchAt && this.timelineRecord.record && this.nextRecord && this.nextRecord.record) {
        if (this.lastWatchAt < this.timelineRecord.record.targetAt && this.lastWatchAt > this.nextRecord.record.targetAt) {
          return true
        }
      }
      return false
    },

    lastWatchAtStr () {
      if (this.lastWatchAt) {
        return moment(this.lastWatchAt).format('最終閲覧日時 : YYYY/MM/DD HH:mm')
      }
      return '最終閲覧日時 : ----/--/-- --:--'
    },

    thanksAuthorMessage () {
      var first = true
      var message = ''
      this.timelineRecord.record.thanksAuthors.forEach(author => {
        if (first) {
          first = false
        } else {
          message += 'と<br />'
        }
        if (author.relationship === 100) {
          message += 'あなた'
        } else {
          message += author.name + 'さん'
        }
      })
      return message + 'が「ありがとう」しています'
    },

    sigininUserDontThanks () {
      var notThanks = true
      this.timelineRecord.record.thanksAuthors.forEach(author => {
        if (author.id === this.loggedInAuthor.id) {
          notThanks = false
        }
      })
      return notThanks
    },

    deletable () {
      if ((this.loggedInAuthor && (this.timelineRecord.record.user_id === this.loggedInAuthor.rawID)) || (this.loggedInAuthor && (this.timelineRecord.record.author_id === this.loggedInAuthor.id))) {
        return true
      }
      return false
    },

    thanksAvaliable () {
      // if (this.loggedInAuthor && this.loggedInAuthor.organization_id) {
      //   return false
      // } else {
        return true
      // }
    },

    isReportRecord () {
      if (this.timelineRecord.record && this.timelineRecord.record.author && this.timelineRecord.record.author.isReporter) {
        return true
      }
      return false
    }
  },

  methods: {
    showAuthorInfo (targetElm, callback) {
      this.$emit('modalOpenEvent', targetElm, callback)
    },

    showQuateThread () { this.$emit('showQuateEvent', this.timelineRecord.record) },

    showReplyRecords () { this.$emit('showReplyEvent', this.timelineRecord.record) },

    narrowDownByTag (tag) {
      this.$emit('recordTagNarrowDownEvent', tag)
    },

    showPhoto (record, firstShowIndex) {
      this.$emit('showRecorPhotoEvent', record, firstShowIndex)
    },

    showDrugEntryPhoto (record, firstShowIndex) {
      this.$emit('showDrugEntryPhotoEvent', record, firstShowIndex)
    },

    openFile (file) {
      this.$emit('openRecordFileEvent', file)
    },

    showVideo (videoContent) {
      this.$emit('showVideoContentEvent', videoContent)
    },

    showAudio (audioContent) {
      this.$emit('showAudioContentEvent', audioContent)
    },

    showThanksAuthors () {
      this.showThanks = true
    },

    closeThanksAuthors () {
      this.showThanks = false
    },

    addThanks () {
      this.$emit('addThanksEvent', this.timelineRecord.record)
    },

    removeThanks () {
      this.$emit('removeThanksEvent', this.timelineRecord.record)
    },

    nudgeRecord () {
      console.log('nudgeRecord')
      this.$emit('nudgeRecordEvent', this.timelineRecord.record)
    },

    confirmDeleteNudge () {
      console.log('confirmDeleteNudge')
      this.deleteNudgeConfirmBoxShow = true
    },

    closeConfirmDeleteNudge () {
      console.log('close confirmDeleteNudge')
      this.deleteNudgeConfirmBoxShow = false
    },

    deleteNudge () {
      console.log('deleteNudge')
      this.deleteNudgeConfirmBoxShow = false
      this.$emit('deleteNudgeEvent', this.timelineRecord.record)
    },

    setReplyRecord () {
      this.$emit('setReplyEvent', this.timelineRecord.record)
    },

    destroyRecord () {
      this.$emit('destroyRecordEvent', this.timelineRecord.record)
    },

    editRecord () {
      this.$emit('editRecordEvent', this.timelineRecord.record)
    },

    quoteRecord () {
      // console.log(this.timelineRecord.record)
      this.$emit('quoteRecordEvent', this.timelineRecord.record)
    },

  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
    // ClickOutside
  }
}
</script>

<style lang="scss" scoped>
@keyframes highlight {
  0% {
    background-color: #f2f2b0;
  }
  100% {
    background-color: #fff;
  }
}

.timeline-record-block {
  margin-bottom: 10px;
  border: 1px solid #aaa;
  background-color: #fff;
  &.highlight {
    animation: highlight 6s ease-out;
  }
}
.record-header {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  .header-icons {
    display: flex;
    img {
      margin-left: 5px;
      width: 20px;
      height: 20px;
    }
  }
}

.record-body-box {
  padding: 10px 5px 0 10px;
}

.record-footer {
  padding: 5px;
  border-top: 1px solid #ddd;
  .record-hashtag {
    display: inline-block;
    cursor: pointer;
  }
  .record-footer-info {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    .footer-left {
      display: flex;
      .record-thanks {
        display: flex;
        cursor: pointer;
        position: relative;
        margin-right: 10px;
        .thanks {
          margin-left: 5px;
        }
        .thanks-authors {
          position: absolute;
          bottom: 30px;
          left: 10px;
          width: 200px;
          padding: 9px 14px;
          border: 1px solid #aaa;
          background-color: #fff;
          border-radius: 3px;
          font-size: 0.7em;
          line-height: 1.6em;
          .add-thanks {
            font-size: 0.8rem;
            color: #692220;
            text-decoration: underline;
          }
          .remove-thanks-bloack {
            display: block;
            &.hide {
              display: none;
            }
            img {
              width: 10px;
            }
          }
        }
      }
      .thanks.disable {
        display: none;
      }
      .record-nudged {
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        .delete-nudge-confirm {
          position: absolute;
          bottom: 10px;
          left: 10px;
          width: 150px;
          padding: 9px 14px;
          text-align: center;
          border: 1px solid #aaa;
          background-color: #fff;
          border-radius: 3px;
        }
      }
      .quated-records {
        font-size: 0.8em;
        font-weight: bold;
        cursor: pointer;
        color: #999;
      }
    }
    .footer-right {
      text-align: right;
      .quote-btn {
        margin-right: 10px;
        cursor: pointer;
        border-radius: 3px;
        border: 1px solid #999;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        vertical-align: bottom;
      }
      .reply-btn {
        cursor: pointer;
        border-radius: 3px;
        border: 1px solid #999;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        vertical-align: bottom;
      }
      .destroy-btn {
        cursor: pointer;
        padding-right: 0;
        border: none;
        background-color: transparent;
        img {
          height: 20px;
          vertical-align: bottom;
        }
      }
      .edit-btn {
        cursor: pointer;
        padding-right: 0;
        border: none;
        background-color: transparent;
        img {
          height: 20px;
          vertical-align: bottom;
        }
      }
    }
  }
}
.timeline-record-dummy-block {
  margin-bottom: 10px;
  border: 1px solid #aaa;
  background-color: #fff;
  &.highlight {
    background-color: #ff0;
  }
  .dummy-header {
    display: flex;
    padding: 5px 10px;
    border: 1px solid #aaa;
    .dummy-portrate {
      margin-right: 10px;
      width: 50px;
      height: 50px;
      background-color: #ddd;
    }
    .dummy-title-line {
      margin-bottom: 5px;
      height: 1em;
      width: 100px;
      background-color: #ddd;
    }
    .dummy-sub-line {
      height: 0.8em;
      width: 200px;
      background-color: #ddd;
    }
  }
  .dummy-body-block {
    padding: 10px;
    border: 1px solid #aaa;
    .dummy-body-line {
      margin-bottom: 5px;
      height: 1em;
      width: 100%;
      background-color: #ddd;
    }
  }
}

.last-watch-at {
  margin: 10px;
  padding-bottom: 3px;
  border-bottom: 1px solid #96514d;
  color: #96514d;
  text-align: right;
}
</style>
